// extracted by mini-css-extract-plugin
export default {"container":"rRJwxeCacfK2dPV_ZR78","fadeIn":"kPi642pwNhFA7Msm7MD1","subTitle":"Z0KxWGd9RLxw_X0J5n17","alertNote":"I8crw1bSOe3ASoy90z4w","signButton":"Np6u3txZZKUreG9iYoNF","saveForLater":"zscnL__5rMa_K0MSNg68","linkButton":"seoOnR5rn9jHeGAyZeaR","modalBody":"BoNOKzZuhoiwTGyDpuJL","htmlContent":"ITsCrYFlYDK33GXIARwA","content":"OfGDpWxo0IAUETIxdLdl","loader":"TuvWA_LfWktMuqmZj1uD","saveLaterLink":"fASKZ8fMlZtcyEP9LWi1"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"rRJwxeCacfK2dPV_ZR78\",\"fadeIn\":\"kPi642pwNhFA7Msm7MD1\",\"subTitle\":\"Z0KxWGd9RLxw_X0J5n17\",\"alertNote\":\"I8crw1bSOe3ASoy90z4w\",\"signButton\":\"Np6u3txZZKUreG9iYoNF\",\"saveForLater\":\"zscnL__5rMa_K0MSNg68\",\"linkButton\":\"seoOnR5rn9jHeGAyZeaR\",\"modalBody\":\"BoNOKzZuhoiwTGyDpuJL\",\"htmlContent\":\"ITsCrYFlYDK33GXIARwA\",\"content\":\"OfGDpWxo0IAUETIxdLdl\",\"loader\":\"TuvWA_LfWktMuqmZj1uD\",\"saveLaterLink\":\"fASKZ8fMlZtcyEP9LWi1\"}";
        // 1726221746283
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  