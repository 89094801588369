/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { Logo } from 'liberis-component-library';

import styles from './Header.scss';
import { AppContext } from '../../context/appContext';

const Header = (): JSX.Element => {
  const { logo } = useContext(AppContext);

  return <Logo className={styles.logo} {...logo} />;
};

export default Header;

