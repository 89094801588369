/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import liberisComponentLibrary from 'liberis-component-library';

import ContractSign from './ContractSign';
import useContract from './useContract';

import style from './ContractSign.scss';
import { SignPageState } from './ContractSign.types';
import SaveLaterModal from './SaveLaterModal';
import useLocale from '../../hooks/useLocale';

const Loading = liberisComponentLibrary.Loading;

export default function Contract(): React.JSX.Element {
  const {
    state,
    onSignContract,
    onSetClickwrapParameters,
    onSendEmailLink,
    onCloseSaveLaterModal,
    handleManualSaveLaterSubmit,
    onContractViewed,
  } = useContract();
  const { getLocale } = useLocale();
  return (
    <>
      {state.mode !== SignPageState.Loading && (
        <>
          <ContractSign
            onSignClick={onSignContract}
            onSetClickwrapParameters={onSetClickwrapParameters}
            onSaveLaterClick={onSendEmailLink}
            onContractViewed={onContractViewed}
            {...state} />
          {state.showSaveLaterModal && (
            <SaveLaterModal
              mode={state.saveLaterModalMode}
              isFailureOccur={!!state.saveLaterRequest?.error}
              isLinkSent={state.saveLaterRequest?.isLoading}
              onManualSaveLaterSubmit={handleManualSaveLaterSubmit}
              onCloseModalClick={onCloseSaveLaterModal}
            />
          )}
        </>
      )}
      {state.mode === SignPageState.Loading && (
        <div aria-busy='true' aria-live='polite' aria-label={getLocale('Loading')} tabIndex={-1}>
          <Loading className={style.loader} isLoading/>
        </div>
      )}
    </>
  );
}
