import { CardReader } from 'liberis-component-library';
import React, { createRef, FC, useEffect, useRef, useState } from 'react';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { ContractRendererProps } from './ContractSign.types';
import { ContractActivity, ActivityEventType } from '../../models/ContractActivity';
import style from './ContractSign.scss';
import useLocale from '../../hooks/useLocale';
import PostService from '../../services/PostService';
import { ApiService } from '../../services';

const ContractRenderer: FC<ContractRendererProps> = ({
  contract,
  onValidHandler,
  onInvalidHandler,
  onSetHandler,
  onClickwrapEventHandler,
  onContractViewed,
}): JSX.Element => {
  const ApiRef = useRef<ApiService>(new ApiService());
  const PostApiRef = useRef<PostService>(new PostService());
  const [renderDataState, setRenderDataState] = useState(contract);
  const [state, setState] = useState({
    repeat: false,
    repeat1: false,
    forceScroll: true,
    downloadUrl: '',
  });
  const newRef = createRef<any>();
  const { getLocale } = useLocale();

  useEffect(() => {
    setRenderDataState(contract);
  }, [contract]);

  // This is a temporary fix while we wait for a more permanent one from Ironclad
  const onEventMethod = (name: any, ...args: any) => {
    if (name == 'rendered' && !state.repeat) {
      const group = args[0];
      setState(pre => ({ ...pre, repeat: true, repeat1: false }));
      group.retrieveHTML({
        ...contract.renderData,
      });

      postViewedActivity(group);
    }

    if (name == 'scrolled' && !state.repeat1) {
      const group = args[1];
      setState((pre) => ({ ...pre, repeat1: true }));

      if (newRef.current) {
        newRef.current.querySelectorAll('.ps-scroll-message').forEach((el: any) => {
          el.style.display = 'block';
        });
        newRef.current.querySelectorAll('.ps-checkbox-clickable').forEach((el: any) => {
          el.style.display = 'none';
        });
        group.setupScrollListeners();
        group.set('display_all', true);
        group.displayRequired();
      }
    }

    if (name === 'checked') onValidHandler(true);
    if (name === 'unchecked') onInvalidHandler();

    // This ensures that any clickwrap events trigger a recalculation of the container height
    onClickwrapEventHandler(name);
  };

  const renderHandler = () => {
    if (newRef.current) {
      newRef.current.querySelectorAll('.ps-contract-body').forEach((el: any) => {
        el.setAttribute('tabindex', '0');
        el.setAttribute('aria-label', 'contract');
        el.setAttribute('data-dd-privacy', 'mask');
      });

      newRef.current.querySelectorAll('.ps-checkbox-clickable').forEach((el: any) => {
        el.setAttribute('data-dd-privacy', 'allow');
      });

      newRef.current.querySelectorAll('.ps-expand-button').forEach((el: HTMLElement) => {
        el.innerText = getLocale('ExpandContract');
        el.addEventListener('click', () => {
          onClickwrapEventHandler('expand');
        });
      });

      newRef.current.querySelectorAll('a.ps-download-link').forEach((el: HTMLAnchorElement) => {
        el.innerText = getLocale('Download');
        el.addEventListener('click', () => {
          const postMsgPayload = {
            status: 'download-requested',
            url: el.href,
          };
          PostApiRef.current.postToParent(JSON.stringify(postMsgPayload));
        });
        if (!state.downloadUrl) {
          setState(pre => ({ ...pre, downloadUrl: el.href }));
          onContractViewed(el.href);
        }
      });

      newRef.current.querySelectorAll('.ps-scroll-message').forEach((el: Element) => {
        (el.children.item(0) as HTMLElement).innerText = getLocale('ForceScrollText');
      });
    }
  };

  const postViewedActivity = async (context: any) => {
    const contractIds = context.get('contracts');
    const groupId = context.get('group');
    const versions = context.get('versions');
    const renderData = renderDataState.renderData;
    const activity: ContractActivity = {
      sid: window.appConfig.pactsafeAccessId,
      sig: contract.ironcladProps.signer,
      gid: groupId,
      gkey: contract.ironcladProps.groupKey,
      vid: versions,
      cid: contractIds,
      cus: renderData,
      rnd: renderData,
      et: ActivityEventType.visited,
    };

    ApiRef.current.postContractActivity(activity);
  };

  return contract.ironcladProps ? (
    <div id='clickwrap-container' ref={newRef}>
      <input type='hidden' id='signerId' value={contract.ironcladProps.signer} />
      <PSClickWrap
        containerId='clickwrap-container'
        accessId={window.appConfig.pactsafeAccessId}
        allowDisagreed
        disableSending
        dynamic
        filter={contract.ironcladProps.filter}
        groupKey={contract.ironcladProps.groupKey}
        onRendered={renderHandler}
        onAll={onEventMethod}
        onSet={onSetHandler}
        renderData={renderDataState}
        signerIdSelector={'signerId'}
        forceScroll={true}
      />
    </div>
  ) : (
    <CardReader className={style.htmlContent} onScrollComplete={onValidHandler} content={contract.content} />
  );
};

export default ContractRenderer;
